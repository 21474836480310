import React, { useContext, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { CiMenuBurger } from "react-icons/ci";
import { CgClose } from "react-icons/cg";
import { DropdownMenu } from "./Dropdown";
import {
  dropdownAndi,
  dropdownInspirations,
  dropdownProducts,
  dropdownSupport,
} from "@/contains";
import logo from "@/assets/images/logo.png";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa6";
import { FaPinterest, FaMobileAlt } from "react-icons/fa";
import { MdLaptopChromebook } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "@/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "@/context/AppContext";
import { getImgUrl } from "@/modules/shared/helper";

export const Header = (): JSX.Element => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { appConfig } = useContext(AppContext);
  const { t } = useTranslation();
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [iShowSubMenu1, setIShowSubMenu1] = useState(false);
  const [iShowSubMenu2, setIShowSubMenu2] = useState(false);
  const [iShowSubMenu3, setIShowSubMenu3] = useState(false);
  const [iShowSubMenu4, setIShowSubMenu4] = useState(false);
  const handleMouseEnter1 = () => setIsHovered1(true);
  const handleMouseLeave1 = () => setIsHovered1(false);
  const handleMouseEnter2 = () => setIsHovered2(true);
  const handleMouseLeave2 = () => setIsHovered2(false);
  const handleMouseEnter3 = () => setIsHovered3(true);
  const handleMouseLeave3 = () => setIsHovered3(false);
  const handleMouseEnter4 = () => setIsHovered4(true);
  const handleMouseLeave4 = () => setIsHovered4(false);

  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
    setIShowSubMenu1(false);
    setIShowSubMenu3(false);
    setIShowSubMenu2(false);
    setIShowSubMenu4(false);
  };
  const handleOpenSubMenu1 = () => {
    setIShowSubMenu1(!iShowSubMenu1);
    setIShowSubMenu2(false);
    setIShowSubMenu3(false);
    setIShowSubMenu4(false);
  };

  const handleOpenSubMenu2 = () => {
    setIShowSubMenu2(!iShowSubMenu2);
    setIShowSubMenu1(false);
    setIShowSubMenu3(false);
    setIShowSubMenu4(false);
  };
  const handleOpenSubMenu3 = () => {
    setIShowSubMenu3(!iShowSubMenu3);
    setIShowSubMenu1(false);
    setIShowSubMenu2(false);
    setIShowSubMenu4(false);
  };
  const handleOpenSubMenu4 = () => {
    setIShowSubMenu4(!iShowSubMenu4);
    setIShowSubMenu1(false);
    setIShowSubMenu3(false);
    setIShowSubMenu2(false);
  };
  const handleSelectLanguage = (event: any) => {
    setLanguage(event.target.value);
  };
  return (
    <div>
      <header className="w-full bg-[#0c3388] border-white px-2 py-2 sm:py-6">
        <div className="h-full gap-2 items-center flex justify-center flex-wrap">
          <div className="flex p-2 h-full w-full items-start md:items-center gap-5 justify-between flex-col md:flex-row lg:justify-center ">
            <div className="flex justify-between w-full md:w-auto items-center flex-row">
              <div className="h-[41px] sm:h-[51px] flex items-center">
                <a href="/" className="h-full object-cover"><img className="h-full object-cover" alt="Logo for AndyStone" src={getImgUrl(appConfig?.logo?.data?.attributes || { url: '/uploads/logo_0ea8daf37f.png' })} /></a>
              </div>
              <nav className="md:hidden flex flex-col items-start gap-1 font-semibold flex-1">
                <button
                  onClick={() => handleOpenMenu()}
                  className="md:hidden font-bold text-xl hover:text-gray-500 self-end"
                >
                  {showMenu ? (
                    <CgClose size={20} color="white" />
                  ) : (
                    <CiMenuBurger size={20} color="white" />
                  )}
                </button>
                {showMenu && (
                  <>
                    <div className="h-full relative w-full">
                      <a
                        onClick={() => handleOpenSubMenu1()}
                        href="#do-nth-block"
                        className={`h-full w-full border-b-[4px] border-b-transparent`}
                      >
                        <p
                          className={`text-white text-lg font-medium ${iShowSubMenu1 && "border-b-[4px]"
                            } ${iShowSubMenu1 && "border-b-[#d33926]"} ${iShowSubMenu1 && "text-[#d33926]"
                            }`}
                        >
                          {t("navigation:home")}
                        </p>
                        {iShowSubMenu1 && <DropdownMenu data={dropdownAndi} />}
                      </a>
                    </div>
                    <div className="h-full relative w-full">
                      <a
                        onClick={() => handleOpenSubMenu3()}
                        id="3"
                        href="#do-nth-block"
                        className={`h-full w-full border-b-[4px] border-b-transparent`}
                      >
                        <p
                          className={`text-white text-lg font-medium ${iShowSubMenu3 && "border-b-[4px]"
                            } ${iShowSubMenu3 && "border-b-[#d33926]"} ${iShowSubMenu3 && "text-[#d33926]"
                            }`}
                        >
                          {t("navigation:products.products")}
                        </p>
                        {iShowSubMenu3 && (
                          <DropdownMenu data={dropdownProducts} />
                        )}
                      </a>
                    </div>
                    <div className="h-full relative w-full">
                      <NavLink
                        to="/design_your_kitchen"
                        className="h-full w-full border-b-[4px] border-b-transparent"
                      >
                        <p className="text-white text-lg font-medium">
                          {t("navigation:inspiration.designKitchen")}
                        </p>
                      </NavLink>
                    </div>
                    <div className="h-full relative w-full">
                      <NavLink
                        to="/catalog"
                        className="h-full w-full border-b-[4px] border-b-transparent"
                      >
                        <p className="text-white text-lg font-medium">
                          {t("navigation:support.catalog")}
                        </p>
                      </NavLink>
                    </div>
                    <div className="h-full relative w-full">
                      <NavLink
                        to="/news"
                        className="h-full w-full border-b-[4px] border-b-transparent"
                      >
                        <p className="text-white text-lg font-medium">
                          {t("navigation:andi.news")}
                        </p>
                      </NavLink>
                    </div>
                    <div className="h-full relative w-full">
                      <NavLink
                        to="/contact_us"
                        className="h-full w-full border-b-[4px] border-b-transparent"
                      >
                        <p className="text-white text-lg font-medium">
                          {t("navigation:support.contact")}
                        </p>
                      </NavLink>
                    </div>
                  </>
                )}
              </nav>
            </div>
            <div className="hidden md:flex h-full items-center flex-col md:flex-row">
              <div className="h-full relative">
                <a
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
                  href="/"
                  className="h-full w-[135px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">{t("navigation:home")}</p>
                  {isHovered1 && <DropdownMenu data={dropdownAndi} />}
                </a>
              </div>
              <div className="h-full relative">
                <a
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                  href="#do-nth-block"
                  className="h-full w-[135px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">
                    {t("navigation:products.products")}
                  </p>
                  {isHovered3 && <DropdownMenu data={dropdownProducts} />}
                </a>
              </div>
              <div className="h-full relative">
                <NavLink
                  to="/design_your_kitchen"
                  className="h-full w-[220px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">
                    {t("navigation:inspiration.designKitchen")}
                  </p>
                </NavLink>
              </div>
              <div className="h-full relative">
                <NavLink
                  to="/catalog"
                  className="h-full w-[135px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">
                    {t("navigation:support.catalog")}
                  </p>
                </NavLink>
              </div>
              <div className="h-full relative">
                <NavLink
                  to="/news"
                  className="h-full w-[135px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">
                    {t("navigation:andi.news")}
                  </p>
                </NavLink>
              </div>
              <div className="h-full relative">
                <NavLink
                  to="/contact_us"
                  className="h-full w-[135px] flex justify-start sm:justify-center items-center border-b-[4px] border-b-transparent hover:border-b-[4px] hover:border-[#d33926] hover:text-[#d33926]"
                >
                  <p className="text-white text-lg font-medium">
                    {t("navigation:support.contact")}
                  </p>
                </NavLink>
              </div>
            </div>
            <div className="w-full md:w-fit">
              <select
                className="bg-white text-gray-600 rounded-lg py-[6px] px-2 outline-none w-full"
                onChange={handleSelectLanguage}
                defaultValue={language}
              >
                <option value="en" selected>
                  English
                </option>
                <option value="vi">Vietnam</option>
              </select>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
